import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import styles from "./footer.module.scss"

const Footer = () => (
  <footer>
    <div className={styles.footerMain}>
      <Container>
        <div className={styles.footerCont}>
          <Row>
            <Col lg={4} className={styles.footerSection}>
              <h5>Address</h5>
              <p>SensusDx, Inc. 62 Church Street, Saratoga Springs, New York 12866</p>
            </Col>
            <Col lg={4} className={styles.footerSection}>
              <h5>Phone</h5>
              <p>+1-518-871-1646</p>
            </Col>
            <Col lg={4} className={styles.footerSection}>
              <h5>Email</h5>
              <a href="mailto:hello@sensusdx.com">hello@sensusdx.com</a>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  </footer>
)

export default Footer
