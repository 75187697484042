import { Link } from "gatsby"
import React from "react"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"

import styles from "./header.module.scss"
import logo from "../images/sensus_dx.png"

const Header = () => {
  const teamPage =
    typeof window !== `undefined`
      ? window.location.href.includes("team")
      : false
  const advisorPage =
    typeof window !== `undefined`
      ? window.location.href.includes("advisor")
      : false

  return (
    <Navbar className={styles.navbar} expand="sm" fixed="top" collapseOnSelect>
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            alt="SensusDx"
            src={logo}
            width="134px"
            className={styles.logo}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          className="justify-content-end"
          id="responsive-navbar-nav"
        >
          <Nav>
            <Link
              to="/team"
              className={`${styles.navbarItem} ${teamPage &&
                styles.activeItem}`}
            >
              Team
            </Link>
            <Link
              to="/advisors"
              className={`${styles.navbarItem} ${advisorPage &&
                styles.activeItem}`}
            >
              Advisors
            </Link>
            <a href="#contact" className={styles.button + " button-primary"}>
              Contact
            </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
export default Header
